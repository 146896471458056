#category {
    margin-top: 3px;
    // height: 400px;
    display: flex;
    padding-bottom: 100px;

    .leftContent {
        width: 200px;
        background-color: whitesmoke;
        padding: 20px 30px;

        h1 {
            color: orangered;
        }

        ul {
            li {
                color: rgb(60, 60, 60);
                list-style: none;
                margin-top: 20px;
                font-size: 18px;
                transition: all .3s;
                cursor: pointer;


                &:hover {
                    color: orangered;
                }
            }
        }
    }

    .mainContent {
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 200px);
        font-size: 100px;

        .slider {
            overflow: scroll;
            display: flex;
            gap: 20px;

            &::-webkit-scrollbar {
                display: none;
                /* Ẩn thanh scroll bar */
            }


            .productItem {
                width: 300px;
                // height: 440px;
                gap: 20px;

                .img {
                    width: 300px;
                    height: 300px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                h3 {
                    margin-top: 10px;
                    font-size: 16px;
                }

                p {
                    font-size: 14px;
                    color: orangered;
                    font-weight: 600;

                }
            }

        }
    }
}


@media screen and (max-width: 768px) {

    #category {
        margin-top: 3px;
        // height: 400px;
        display: flex;
        flex-direction: column;
        width: 100%;



        .leftContent {
            padding: 10px;
            width: 100%;




            h1 {
                font-size: 18px;
            }

            ul {
                display: flex;
                gap: 30px;
                margin-top: 5px;
                overflow-x: scroll;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }


                li {
                    font-size: 14px;
                    display: block;
                    width: 100%; // không chình được width
                    margin: 5px 0 0 0;

                    &:last-child {
                        margin-right: 20px;
                    }




                    &:hover {
                        color: orangered;
                    }
                }
            }
        }

        .mainContent {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }


}
.content {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    // background-color: red;

    .text {
        h3 {
            margin: 20px 0 0;
        }

        h1 {
            margin: 20px 0 0;


        }

        h4 {
            color: orangered;
        }

        button {
            padding: 10px 20px;
            margin: 20px auto;
            background-color: #009900;
            color: white;
            border: none;
            font-size: 18px;
            font-weight: 600;
            border-radius: 10px 0 10px 0;

            i {
                font-size: 16px;
            }
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid silver;

        tbody {
            tr {
                td {
                    border: 1px solid silver;
                    padding: 5px 10px;

                    &:first-child {
                        width: 160px;
                        font-weight: 500;
                        background-color: aliceblue;
                    }

                    ul {
                        margin-left: 15px;

                        li {}
                    }

                }
            }
        }
    }



}

@media screen and (max-width: 768px) {
    .content {
        margin: 0 auto;
        flex-direction: column;
        padding-bottom: 100px;

        .productItem {
            margin: 0 auto;
        }


        .img {
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }

}
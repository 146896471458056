#advancon {
    background-color: whitesmoke;
    padding: 50px;
    display: flex;
    justify-content: space-around;
    gap: 20px;


    .advanIconWrap {




        cursor: pointer;

        .advanIcon {
            width: 160px;
            height: 160px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .5s;

            img {
                width: 100%;
                height: 100%;
            }


        }

        h3 {
            margin-top: 20px;
            color: rgb(60, 60, 60);
            text-align: center;
            transition: all .3s;
        }

        &:hover {
            .advanIcon {
                scale: 1.2;
            }

            h3 {
                color: orangered;
            }
        }
    }

}

@media screen and (max-width: 768px) {
    #advancon {
        padding: 10px;
        display: flex;
        justify-content: space-around;
        gap: 20px;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
            /* Ẩn thanh scroll bar */
        }


        .advanIconWrap {




            cursor: pointer;

            .advanIcon {
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .5s;

                img {
                    width: 100%;
                    height: 100%;
                }


            }

            h3 {
                margin-top: 10px;
                color: rgb(60, 60, 60);
                text-align: center;
                transition: all .3s;
                font-size: 14px;
            }

            &:hover {
                .advanIcon {
                    scale: 1.2;
                }

                h3 {
                    color: orangered;
                }
            }
        }

    }
}
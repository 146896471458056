#footer {
    height: 80px;
    background-color: rgb(56, 46, 46);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    h4 {
        color: white;
    }

    p {
        color: white;

        a {
            text-decoration: none;
            color: white;
        }

        i {
            font-size: 14px;
            color: silver;
        }
    }
}
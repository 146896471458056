* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;

}

.content {
    // min-height: calc(100vh - 80px);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;
    width: 940px;
    margin: 30px auto;
    padding-bottom: 100px;


}


@media screen and (max-width: 768px) {
    .content {
        width: 100%;
        justify-content: center;
        padding: 10px;

        .productItem {
            width: 400px;
        }

    }

}
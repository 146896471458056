.content {
    display: flex;
    gap: 20px;

    .productItem {
        width: 300px;
        // height: 340px;
        padding: 10px;
        // border: 1px solid silver;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;

        .img {
            // width: 300px;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

        }

        h3 {
            margin-top: 20px;
            height: 40px;
            font-size: 16px;
        }

        p {
            margin-top: 10px;
            color: orangered;
            font-weight: 600;
        }


    }
}

@media screen and (max-width: 768px) {
    .content {
        .productItem {
            width: 380px;
        }
    }
}
#banner {
    // padding-top: 30px;
    // height: 600px;
    // background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
    }
}